#campoNumeroLicencias {
    display: flex;
    flex-direction: row;
}

.form-generarLicencias {
    width: 300px;
    margin-left: 60px;
}

#form-control-empresas {
    height: 35px;
}
#radio-numero {
    margin: 5px;
}

#varias-licencias-individuales {
    display: none;
}