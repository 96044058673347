.modal-tunning {
    margin-top: 10%;
    left: 0;
}
.btn-modificar {

    margin-left: 10px;
}
img {
    pointer-events: none;
    border-radius: 50%;
    width: 20px;
}


